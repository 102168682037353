import React from 'react';
import { SplashScreen } from '@piwikpro/ui-components';
import { AccessContext, IAccessContext, UserAccessContext } from './AccessContext';

type Subtract<T, K> = Omit<T, keyof K>;

// eslint-disable-next-line max-len
const view = <AllProps, SkippedProps>(accessContext: UserAccessContext, display = true) => ComponentToWrap => (
  class SecuredView extends React.Component<Subtract<AllProps, SkippedProps>> {
    static contextType = AccessContext;

    render() {
      const { accessManager } = this.context as IAccessContext;

      if (!accessManager?.isGranted(accessContext)) {
        if (display) {
          return (
            <SplashScreen type="noPrivileges" />
          );
        }

        return null;
      }

      return (
        <ComponentToWrap
          {...this.props}
        />
      );
    }
  }
);

export default view;
