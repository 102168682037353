import React from 'react';
import classNameBind from 'classnames/bind';
import { History } from 'history';
import {
  Stack,
  TextStyle,
  Text,
  ModalView,
  Header,
  ProgressStepper,
  Button,
  Image,
  Trans,
  Link,
} from '@piwikpro/ui-components';
import { withInject } from '@piwikpro/platform';
import { App } from '@piwikpro/apps-crate';
import styles from './FirstSteps.module.css';
import docktorImg from './doctor.png';

const bindClasses = classNameBind.bind(styles);

interface InjectedProps {
  history: History
}

interface StateProps {
  isAppsListLoading?: boolean
  firstApp?: App
}

export interface IFirstStepsProps extends StateProps, InjectedProps {
  onClose: () => void
  t: (key: string) => string
}

export const FirstSteps: React.FunctionComponent<IFirstStepsProps> = ({
  isAppsListLoading,
  firstApp,
  onClose,
  history,
  t,
}) => (
  <ModalView
    title=""
    dataId="first-steps-guide-modal" // used to encapsulate overwritten styles
  >
    <Stack
      vertical
      horizontalAlignment="middle"
      verticalAlignment="center"
      spacing="extraWide"
    >
      <Stack.Item fill>
        <div className={bindClasses('img-wrapper')}>
          <Image src={docktorImg} />
        </div>
      </Stack.Item>
      <Stack.Item fill>
        <div style={{ marginTop: '-24px' }}>
          <Stack
            vertical
            spacing="narrow"
            horizontalAlignment="middle"
            verticalAlignment="center"
          >
            <Stack.Item>
              <Header type="large">
                {t('general.guides.first-steps.main-header')}
              </Header>
            </Stack.Item>
            <Stack.Item>
              <TextStyle type="large">
                <div style={{ width: '400px', textAlign: 'center' }}>
                  {t('general.guides.first-steps.main-text')}
                </div>
              </TextStyle>
            </Stack.Item>
          </Stack>
        </div>
      </Stack.Item>
      <Stack.Item fill>
        <Stack
          vertical
          spacing="narrow"
          horizontalAlignment="middle"
          verticalAlignment="center"
        >
          <Stack.Item>
            <ProgressStepper.Step status="current" index={1} />
          </Stack.Item>
          <Stack.Item>
            <Header type="small" cutText={false}>
              {t('general.guides.first-steps.first-step-text')}
            </Header>
          </Stack.Item>
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <div className={bindClasses('separator')} />
      </Stack.Item>
      <Stack.Item fill>
        <Stack
          vertical
          spacing="narrow"
          horizontalAlignment="middle"
          verticalAlignment="center"
        >
          <Stack.Item>
            <ProgressStepper.Step status="current" index={2} />
          </Stack.Item>
          <Stack.Item>
            <Header type="small" cutText={false}>
              {(
                <div style={{ width: '230px', textAlign: 'center' }}>
                  {t('general.guides.first-steps.second-step-text')}
                </div>
              ) as unknown as string}
            </Header>
          </Stack.Item>
        </Stack>
      </Stack.Item>
      <Stack.Item fill>
        <div className={bindClasses('bottom-container')}>
          <Stack
            vertical
            horizontalAlignment="middle"
            verticalAlignment="center"
          >
            <Stack.Item>
              <Button
                disabled={isAppsListLoading}
                appearance="primary"
                text={t('general.guides.first-steps.action-confirm')}
                onClick={() => {
                  onClose();
                  history.push(
                    firstApp
                      ? `/administration/apps/${firstApp.id}/installation`
                      : '/administration/apps',
                  );
                }}
              />
            </Stack.Item>
            <Stack.Item>
              <Text>
                <Trans
                  i18nKey="general.guides.first-steps.action-question"
                  components={[
                    <Link href="https://help.piwik.pro/support/questions/how-do-i-set-up-piwik-pro-after-using-google-universal-analytics-ga3-and-google-tag-manager/">
                      Read our guide
                    </Link>,
                  ]}
                >
                  Trans text
                </Trans>
              </Text>
            </Stack.Item>
          </Stack>
        </div>
      </Stack.Item>
    </Stack>
  </ModalView>
);

export default withInject<InjectedProps>({
  history: 'RouterCrate.history',
})(FirstSteps);
